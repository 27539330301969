<template>
  <div>
    <Pane style="display: flex;justify-content: space-between;">
      <a-button @click="qrModel=true">人脸录入二维码</a-button>
    </Pane>
    <a-modal style="text-align: center;" title="实习生人脸录入" :visible="qrModel" :footer="null" @cancel="qrModel=false">
        <img :src="image" style="width: 100%;"/>
        <a-button @click="downLoadQR">下载二维码</a-button>
    </a-modal>
    <a-modal title="导入" :visible="dialog" :footer="null" @cancel="dialog=false">
    <span>
        <input ref="upload" style="display: none" type="file" @change="onUpload" />

        <a-button :loading="loading" @click="onClick" style="margin-right: 10px;"
        >导入</a-button>
        <!-- <a href="javacript:void(0)" @click="download">下载模板</a> -->
    </span>
    </a-modal>
    <a-card class="container">
      <a-row>
        <a-col :span="20">
          <a-form-model :colon="false" :model="form" layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-input
                placeholder="姓名"
                v-model="form.name"
                style="width: 150px"
              />
            </a-form-model-item>

            <a-form-model-item>
              <a-input
                placeholder="手机号"
                v-model="form.mobile"
                style="width: 150px"
              />
            </a-form-model-item>

            <a-form-item>
              <a-select
                placeholder="性别"
                v-model="form.sex"
                style="width: 150px"
              >
                <a-select-option
                    v-for="item in genderList"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            
            <a-form-model-item>
              <a-select
                v-model="form.deptId"
                placeholder="实习部门"
                show-search
                :filter-option="$selectFilterOption"
                style="width: 150px"
              >
                <a-select-option
                  v-for="item in organizationList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.uniqueName }}</a-select-option
                >
              </a-select>
            </a-form-model-item>

            <a-form-item>
              <a-select
                placeholder="人脸通行"
                v-model="form.faceAuth"
                style="width: 150px"
              >
                <a-select-option
                      v-for="item in [
                      { name: '已开通', value: true },
                      { name: '未开通', value: false },
                    ]"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-model-item>
              <a-select v-model="form.status2" placeholder="实习状态" style="width: 150px">
                <a-select-option
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            
            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="4">
          <div class="right" style="margin-top: 4px">
            <a-space>
              <a-button v-if="$getPermission($route.path + '/edit')"  @click="dialog=true" style="margin-right: 10px;">导入</a-button>
              <a-button
                v-if="$getPermission($route.path + '/add')"
                @click.prevent="download()"
                style="margin-right: 10px;"
              >导出台账</a-button>
              <a-button
                type="primary"
                v-if="$getPermission($route.path + '/add')"
                @click="$router.push($route.path + '/add')"
              >新增人员</a-button>
            </a-space>
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey="id"
      >
        <a-table-column title="姓名" data-index="name"></a-table-column>
        <a-table-column title="实习部门" data-index="deptName" align="center"></a-table-column>
        <a-table-column title="对接人" data-index="contact"></a-table-column>
        <a-table-column title="报备时间" data-index="registerTime" width="160px" align="center"></a-table-column>
        <a-table-column title="性别" width="100px" align="center">
          <template slot-scope="text">
            <DataDictFinder
                dictType="sex"
                :dictValue="text.sex"
              />
          </template>
        </a-table-column>
        <a-table-column title="本科学校" data-index="bachelorSchool"></a-table-column>
        <a-table-column title="硕士学校" data-index="masterSchool"></a-table-column>
        
        <a-table-column title="实习时段">
          <template slot-scope="text">
            <span>{{ text.internFrom }}</span>至
            <span>{{ text.internTo }}</span>
          </template>
        </a-table-column>
        <a-table-column title="人脸通行">
          <template slot-scope="text">
            <span v-if="text.faceAuth">已开通</span>
            <span v-else>未开通</span>
          </template>
        </a-table-column>
        <a-table-column title="实习状态" width="100px" align="center">
          <template slot-scope="text">
            <DataDictFinder
                dictType="internStatus"
                :dictValue="text.status2"
                iconType="badge"
                badgeType="color"
              />
          </template>
        </a-table-column>
        

        <a-table-column title="操作" align="center" width="140px">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                v-if="$getPermission($route.path + '/detail')"
                @click.prevent="
                  $router.push(`${$route.path}/detail?id=${text.id}`)
                "
              >
                详情
              </a>
              <a
                href="#"
                v-if="$getPermission($route.path + '/edit')"
                @click.prevent="
                  $router.push(`${$route.path}/edit?id=${text.id}`)
                "
              >
                编辑
              </a>
              <a href="#" v-if="$getPermission($route.path + '/edit')" class="danger" @click.prevent="remove(text)">删除</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import EmployeeSelector from "@/components/employee-selector";
import watermark from "@/mixins/watermark";
import request from "@/api/request";
import study from "@/mixins/study";
import download from '@/api/download'
import { mapGetters } from "vuex";
import { saveAs } from "file-saver";
function fetchList(data) {
  return request({
    url: "/user-service/intern/list",
    method: "post",
    data
  });
}

function exportFile(data) {
  return download({
    url: "/user-service/intern/export",
    method: "post",
    data
  });
}

function importChangeList(data) {
  return request({
      url: "/user-service/intern/import",
      method: 'post',
      data
  });
}
function del(data) {
  return request({
    url: "/user-service/intern/delete",
    method: "post",
    data
  });
}

export default {
  mixins: [watermark, study, organization],
  components: {
    EmployeeSelector,
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    statusList() {
      return this.findDataDict("internStatus");
    },
    genderList() {
      return this.findDataDict("sex");
    },
  },
  data() {
    return {
      image: require("@/assets/internQR.png"),
      qrModel: false,
      dialog:false,
      master: {},
      form: {},

      loading: false,
      list: [],
      total: 0,
      current: 1,
      pageSize: 10
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    downLoadQR(){
      var alink = document.createElement("a");
      alink.href = this.image;
      alink.download = '二维码';
      alink.click();
    },
    onClick(){
        this.$refs.upload.click();
    },
    onUpload(e) {
        const files = e.target.files;
        const data = new FormData();
        for (let i = 0; i < files.length; i++) {
            data.append("file", files[i]);
        }

        this.loading = true;

        importChangeList(data)
            .then(() => {
            })
            .finally(() => {
              this.loading = false;
              e.target.value = null;
              this.getList();
            });
    },
    download() {
        exportFile({
          deptId: this.form.deptId,
          faceAuth: this.form.faceAuth,
          name: this.form.name,
          sex: this.form.sex,
          status2: this.form.status2,
          mobile: this.form.mobile
        })
        .then(blob => {
            saveAs(blob, "台账.xlsx");
        });
    },
    getList() {
      this.loading = true;
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        deptId: this.form.deptId,
        faceAuth: this.form.faceAuth,
        name: this.form.name,
        sex: this.form.sex,
        status2: this.form.status2,
        mobile: this.form.mobile
      })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize || 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.current = 1;
      this.form = {};
      this.master = {};
      this.getList();
    },
    remove(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          del({
            id: text.id
          })
            .then(() => {
              that.getList();
            })
            .finally(() => {
            });
        }
      });
    }
  }
};
</script>
